import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, ListGroup, Modal, Offcanvas, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";

export default function RMA() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const checks: Action[] = [];
	const [showChargeSolution, setShowChargeSolution] = useState<boolean>(false);
	const handleCloseChargeSolution = () => setShowChargeSolution(false);
	const handleShowChargeSolution = () => setShowChargeSolution(true);

	const [showEarbudDetectionSolution, setShowEarbudDetectionSolution] = useState<boolean>(false);
	const handleCloseEarbudDetectionSolution = () => {
		setShowEarbudDetectionSolution(false);
		setShowChargeSolution(true);
	};
	const handleShowEarbudDetectionSolution = () => setShowEarbudDetectionSolution(true);

	type Action = {
		id: string;
		action: string;
		question: string;
		RMA: "immediate" | "noSolve" | "no";
		solve?: string;
	};

	const visualInspection: Action[] = [];
	visualInspection.push(
		{
			id: "visual_inspection1",
			action: "Check on severe visual damage/misuse",
			question: "Is the entire unit undamaged?",
			RMA: "no",
		},
		{
			id: "visual_inspection2",
			action: "Check if eargels are properly fixed",
			question: "Are the eargels properly fixed?",
			RMA: "no",
		}
	);

	const general: Action[] = [];
	general.push(
		{
			id: "general1",
			action: "Open the cradle door",
			question: "Middle LED turns on green/orange?",
			RMA: "no",
			solve: "charge",
		},
		{
			id: "general2",
			action: "Wait 7 seconds",
			question: "All LEDs are off?",
			RMA: "no",
		},
		{
			id: "general3",
			action: "Press the cradle button",
			question: "Middle LED turns green/orange",
			RMA: "immediate",
		},
		{
			id: "general4",
			action: "Put left earbud back in the cradle",
			question: "Left LED turns on green for 1 second?",
			RMA: "no",
			solve: "earbud_detection",
		},
		{
			id: "general5",
			action: "Put right earbud back in the cradle",
			question: "Right LED turns on green for 1 second?",
			RMA: "no",
			solve: "earbud_detection",
		}
	);

	checks.push(...visualInspection, ...general);

	function PopulateChecks() {
		var temp: JSX.Element[] = [];

		temp.push(
			<>
				<Row>
					<Col
						sm={6}
						className="fw-bold"
					>
						{" "}
						Action
					</Col>
				</Row>
				<Row>
					<Col sm={6}>Question</Col>
					<Col sm={1}>Yes</Col>
					<Col sm={1}>No</Col>
				</Row>
			</>
		);
		checks.forEach((check) => {
			temp.push(
				<ListGroup.Item
					key={check.id}
					action
				>
					<Row>
						<Col className="fw-bold">{check.action}</Col>
					</Row>
					<Row>
						<Col sm={6}>{check.question}</Col>
						<Col sm={1}>
							<Form.Check
								name={`${check.id}`}
								type="radio"
							></Form.Check>
						</Col>
						<Col sm={1}>
							<Form.Check
								name={`${check.id}`}
								type="radio"
								onClick={() => handleNegativeOption(check.solve)}
							></Form.Check>
						</Col>
					</Row>
				</ListGroup.Item>
			);
		});

		if (temp !== undefined) return <>{temp}</>;
		else return <></>;
	}

	function handleNegativeOption(option: string | undefined) {
		switch (option) {
			case "charge":
				handleShowChargeSolution();
				break;
			case "earbud_detection":
				handleShowEarbudDetectionSolution();
				break;
			default:
				break;
		}
	}

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="homepage"
						/>
						<h1>RMA Questionnaire</h1>
						<ListGroup>{PopulateChecks()}</ListGroup>
					</Container>

					{/* CHARGING SOLUTION */}
					<Modal
						show={showChargeSolution}
						onHide={handleCloseChargeSolution}
						placement="end"
						backdrop="static"
					>
						<Modal.Header closeButton>
							<Offcanvas.Title className="fw-bold">Charge</Offcanvas.Title>
						</Modal.Header>
						<Modal.Body>
							<Row className="small">Follow the steps below to try and solve the problem</Row>
							<hr />
							<Row className="fw-bold">
								<Icon.Icon1CircleFill /> <br /> Connect the USB-charger to the cradle
							</Row>
							<br />
							<Row>All 3 LEDs turn on and are flashing during charging?</Row>
							<br />
							<Row className="gap-2">
								<Button
									disabled
									variant="success"
								>
									Yes, continue to next question
								</Button>
								<Button>No</Button>
							</Row>
							<hr />
							<Row className="fw-bold">
								<Icon.Icon2CircleFill /> <br /> Charge for 30 minutes, open cradle door
							</Row>
							<br />
							<Row>All LEDs are green?</Row>
							<br />
							<Row className="gap-2">
								<Button
									onClick={handleCloseChargeSolution}
									variant="success"
								>
									Yes, quit charge fix
								</Button>
								<Button>No</Button>
							</Row>
						</Modal.Body>
					</Modal>

					{/* EARBUD DETECTION SOLUTION */}
					<Modal
						show={showEarbudDetectionSolution}
						onHide={handleCloseEarbudDetectionSolution}
						placement="end"
						backdrop="static"
					>
						<Modal.Header closeButton>
							<Offcanvas.Title className="fw-bold">Earbud Detection</Offcanvas.Title>
						</Modal.Header>
						<Modal.Body>
							<Row className="small">Follow the steps below to try and solve the problem</Row>
							<hr />

							<Row className="fw-bold">
								<Icon.Icon1CircleFill /> <br />
								Clean cradle and earbud contacts,
							</Row>
							<Row className="fw-bold">
								{" "}
								<br />
								Make sure the eargels are assembled properly,
							</Row>
							<Row className="fw-bold">
								{" "}
								<br />
								take both earbuds out of the cradle and put the left one back in the cradle
							</Row>
							<br />
							<Row>Left LED turns on green for 1 second?</Row>
							<br />
							<Row className="gap-2">
								<Button
									disabled
									variant="success"
								>
									Yes, continue to next step
								</Button>
								<Button>No</Button>
							</Row>
							<hr />
							<Row className="fw-bold">
								<Icon.Icon2CircleFill /> <br />
								Put the right earbud back in the cradle
							</Row>
							<br />
							<Row>Right LED turns on green for 1 second?</Row>
							<br />
							<Row className="gap-2">
								<Button
									onClick={handleCloseEarbudDetectionSolution}
									variant="success"
								>
									Yes, quit earbud detection fix and go to charge
								</Button>
								<Button>No</Button>
							</Row>
						</Modal.Body>
					</Modal>
				</>
			)}
			{!isAuthenticated && (
				<NotSignedIn/>
			)}
		</>
	);
}
