import "bootstrap/dist/css/bootstrap.css";

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { addConsumer, addProductionOrder, getArticles, getConsumers, getCustomer, getDepartments, getOrder, getProductionOrders, lockOrder, removeOrder } from "../api/orderportal_apimanager";
import { Article } from "../api/types/Article";
import { Consumer } from "../api/types/Consumer";
import { Customer } from "../api/types/Customer";
import { Department } from "../api/types/Department";
import { ListItem } from "../api/types/ListItem";
import { Order } from "../api/types/Order";
import { ProductOrder } from "../api/types/ProductOrder";
import { ProductionOrderType } from "../api/types/ProductionOrderType";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import ProductionOrder from "../modules/productionorder";
import StatusComponent from "../modules/statuscomponent";
import toastManager from "../modules/toastmanager";
import { toast } from "sonner";
import { Axios, AxiosError } from "axios";

export default function OrderDetails() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const queryParameters = new URLSearchParams(window.location.search);
	const orderID = queryParameters.get("orderid");

	const [showAddUser, setShowAddUser] = useState(false);
	const [orderSizeWarning, setOrderSizeWarning] = useState<boolean>(false);

	const [customer, setCustomer] = useState<Customer | undefined>();
	const [departments, setDepartments] = useState<ListItem[]>([]);

	const [orderStatus, setOrderStatus] = useState<string>("");
	const [orderProgress, setOrderProgress] = useState<number>(0);
	const [order, setOrder] = useState<Order | undefined>();
	const [productionOrders, setProductionOrders] = useState<ProductionOrderType[]>([]);
	const [productionOrdersReceived, setProductionOrdersReceived] = useState<boolean>(false);

	const [articlesList, setArticlesList] = useState<ListItem[]>([]);
	const [articles, setArticles] = useState<Article[]>([]);
	const [orderProducts, setOrderProducts] = useState<ProductOrder[]>([]);

	const [receivedConsumers, setReceivedConsumers] = useState<boolean>(false);
	const [consumers, setConsumers] = useState<Consumer[]>([]);
	const [consumersList, setConsumersList] = useState<ListItem[]>([]);
	const [selectedConsumers, setSelectedConsumers] = useState<ListItem[]>([]);
	const [showOrderCreated, setShowOrderCreated] = useState<boolean>(false);
	const [showAreYouSure, setShowAreYouSure] = useState<boolean>(false);
	const [productionOrdersArray, setProductionOrdersArray] = useState<JSX.Element[]>([]);
	const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>();

	const handleAddUserOpen = () => setShowAddUser(true);

	function handleAddUserClose() {
		setShowAddUser(false);
	}

	const handleAreYouSureOpen = () => {
		setProductionOrdersReceived(false);
		setShowAreYouSure(true);
	};

	const handleAreYouSureClose = () => setShowAreYouSure(false);

	function handleNewOrder(event: any) {
		event.preventDefault();
		createOrdersInAPI(orderProducts);
		setSelectedConsumers([]);
		setOrderProducts([]);
	}

	function AddProductToList(value: any, cons: ListItem) {
		var temp: ProductOrder[] = orderProducts;
		for (let index = 0; index < orderProducts.length; index++) {
			if (orderProducts[index].consumer === cons.value) {
				orderProducts.splice(index, 1);
			}
		}
		temp.push({ consumer: cons.value, product: value!.value });
		setOrderProducts(temp);
	}

	interface Props {
		products: ListItem[];
		selectedConsumers: ListItem[];
		disabled?: boolean;
	}

	function checkIfInList(first: string, last: string, short: string): boolean {
		var returnvalue = false;
		consumers.forEach((consumer) => {
			if (consumer.given_name.toLowerCase() === first.toLowerCase() && consumer.family_name.toLowerCase() === last.toLowerCase() && consumer.short_name.toLowerCase() === short.toLowerCase())
				returnvalue = true;
		});
		return returnvalue;
	}

	function handleAddUser(event: any) {
		var etf = event.target.form;
		if (!checkIfInList(etf.given_name.value, etf.family_name.value, etf.short_name.value)) {
			if (etf.given_name.value && etf.family_name.value && etf.short_name.value && selectedDepartment !== undefined && order?.customer_id)
				addConsumer(order?.customer_id, etf.given_name.value, etf.family_name.value, etf.short_name.value, selectedDepartment!).then((result) => {
					toastManager("success", `Added ${etf.given_name.value} ${etf.family_name.value}`, `Successfully added ${etf.given_name.value} ${etf.family_name.value} to ${customer?.name}`);
					setReceivedConsumers(false);
					handleAddUserClose();
					var temp = selectedConsumers;
					temp.push({
						label: `${etf.given_name.value} ${etf.family_name.value} (${etf.short_name.value})`,
						value: result.id,
					});
				});
			else {
				//TODO update to toast.warning when package is updated
				toastManager("warning", `Not all required fields are filled in`, `Please fill in all the required fields to continue`);
			}
		} else {
			toastManager(
				"error",
				`User already exists`,
				`${etf.given_name.value} ${etf.family_name.value} already exists. If there are 2 or more users with the same first and last name please choose a unique nickname for each of them.`
			);
		}
	}

	function SelectProducts(props: Props) {
		var consumerArray: JSX.Element[] | undefined = [];
		var k = 0;
		props.selectedConsumers.forEach((cons) => {
			consumerArray?.push(
				<Col key={k}>
					<h5>{cons.label}</h5>
					<Select
						id={cons.value}
						required
						isDisabled={props.disabled}
						options={props.products}
						isSearchable
						onChange={(value) => {
							AddProductToList(value, cons);
						}}
					/>
					<br />
					<br />
				</Col>
			);
			k++;
		});
		if (consumerArray !== undefined) {
			return <>{consumerArray}</>;
		} else {
			return <></>;
		}
	}

	function findConsumer(consumerid: string) {
		for (let index = 0; index < consumers.length; index++) {
			const element = consumers[index];
			if (element.id === consumerid) return element;
		}
	}

	function findArticle(articlecode: string) {
		for (let index = 0; index < articles.length; index++) {
			const element = articles[index];
			if (element.item_code === articlecode) return element;
		}
	}

	// Add production orders to the order
	async function createOrdersInAPI(data: ProductOrder[]) {
		setShowOrderCreated(true);
		setProductionOrdersReceived(true);
		var temp: ProductionOrderType[] = productionOrders;
		for (const order of data) {
			const t = await addProductionOrder(orderID!, order);
			var cons = findConsumer(order.consumer);
			var art = findArticle(order.product);
			toastManager("success", `Added production order for: ${cons?.given_name} ${cons?.family_name}`, `Successfully added ${art?.description} for ${cons?.given_name} ${cons?.family_name}`);
			if (!t.id) {
				toastManager("error", `Something went wrong`, `${t}`);
			} else {
				temp.push(t);
			}
		}
		setProductionOrders(temp);
		setProductionOrdersReceived(false);
		setShowOrderCreated(false);
	}

	function lockOrders(event: any) {
		event.preventDefault();
		handleAreYouSureClose();
		const result = lockOrder(order!.id);
		var toastID = toast.promise(result, {
			loading: "Sending order to EarsOnly..",
			success: (data) => {
				setOrder(data);
				return `Order with reference '${data.referenceId}' containing ${data.production_orders.length} production order(s) has been sent to EarsOnly. It will be produced after the order has been confirmed by EarsOnly.`;
			},
			error: (error: any) => {
				setOrder(undefined);
				// close toast, this error is not useful as an error message
				toast.dismiss(toastID);
				return "";
			},
		});
	}

	function handleRemoveOrder(event: any) {
		var toastID = toast.message(`Remove this order`, {
			action: (
				<Button
					onClick={() => {
						removeOrder(orderID!).then((response: Order) => {
							if (response.referenceId) {
								toastManager("warning", `Removed order`, `Removed order with reference '${response.referenceId}`);
								toast.dismiss(toastID);
								navigate(-1);
							} else {
								toastManager("error", `Something went wrong`, `Error: ${response}`);
							}
						});
					}}
					variant="success"
				>
					<Icon.Check size={15} />
				</Button>
			),
			cancel: (
				<Button
					onClick={() => toast.dismiss(toastID)}
					variant="danger"
				>
					<Icon.X size={15} />
				</Button>
			),
			duration: 15000,
		});
	}

	useEffect(function ResetOrderList() {
		setSelectedConsumers([]);
		setOrderProducts([]);
	}, []);

	useEffect(
		function PopulateProductionOrdersList() {
			if (!productionOrdersReceived) {
				if (order && consumers.length !== 0) {
					var temp: JSX.Element[] = [];
					productionOrders.forEach((productionOrder) => {
						temp?.push(
							<ProductionOrder
								key={productionOrder.id}
								productionOrder={productionOrder}
								articles={articles}
								order={order}
								consumers={consumers}
							/>
						);
					});
					setProductionOrdersArray(temp);
					if (productionOrdersArray.length > 0) {
						setProductionOrdersReceived(true);
					}
				}
			}
		},
		[articles, consumers, order, productionOrders, productionOrdersArray.length, productionOrdersReceived]
	);

	useEffect(
		function RetrieveProductionOrders() {
			if (order && !productionOrdersReceived && token && token.length) {
				var temp: ProductionOrderType[] = [];
				getProductionOrders(token, order?.id).then((result: ProductionOrderType[]) => {
					result.forEach((element) => {
						temp.push(element);
					});
					setProductionOrders(temp);
				});
			}
		},
		[order, productionOrdersReceived, token]
	);

	useEffect(
		function UpdateProgressBar() {
			const amountOfStatusOptions = 6;
			const increasePercentage = 100 / amountOfStatusOptions;
			if (order) {
				var percent = 0;
				if (order.created !== null) {
					setOrderStatus("Created");
					percent += increasePercentage;
				}
				if (order.locked !== null) {
					setOrderStatus("Locked");
					percent += increasePercentage;
				}
				if (order.confirmed !== null) {
					setOrderStatus("Confirmed");
					percent += increasePercentage;
				}
				if (order.inProduction !== null) {
					setOrderStatus("In production");
					percent += increasePercentage;
				}
				if (order.shipping !== null) {
					setOrderStatus("Shipping");
					percent += increasePercentage;
				}
				if (order.completed !== null) {
					setOrderStatus("Completed");
					percent += increasePercentage;
				}
				setOrderProgress(percent);
			}
		},
		[order]
	);

	useEffect(
		function RetrieveOrder() {
			if (order === undefined && token && token.length) {
				if (orderID)
					getOrder(token, orderID).then((o) => {
						setOrder(o as Order);
					});
			}
		},
		[order, orderID, token]
	);

	useEffect(
		function RetrieveCustomersFromCompany() {
			if (!receivedConsumers && order?.customer_id && token && token.length) {
				var temp: Consumer[] = [];
				getConsumers(token, order?.customer_id).then((result) => {
					var cons: ListItem[] = [];
					if (result)
						result.forEach((consumer: Consumer) => {
							temp.push(consumer);
							var item: ListItem = {
								label: `${consumer.given_name} ${consumer.family_name} (${consumer.short_name})`,
								value: consumer.id,
							};
							cons.push(item);
						});
					setConsumers(temp);
					setConsumersList(cons);
					setReceivedConsumers(true);
				});
			}
		},
		[order?.customer_id, receivedConsumers, token]
	);

	const exactDateToTimestamp = (exactDate: string) => {
		const content = /\d+/.exec(String(exactDate));
		const timestamp = content ? Number(content[0]) : 0;
		return timestamp;
	};

	useEffect(
		function RetrieveArticles() {
			if (articlesList.length === 0 && token && token.length) {
				getArticles(token).then((result) => {
					const currentDate = Date.now();
					if (result) {
						var availableArticles: ListItem[] = [];
						var unavailableArticles: ListItem[] = [];
						result.forEach((article: Article) => {
							articles.push(article);
							var disabled = article.deleted;
							var startDate = exactDateToTimestamp(article.start_date);
							var endDate = exactDateToTimestamp(article.end_date);
							if (currentDate < startDate || (endDate < currentDate && endDate !== 0)) {
								disabled = true;
							}
							var item: ListItem = {
								label: `${article.description}`,
								value: article.item_code.toString(),
								isDisabled: disabled,
							};
							if (!disabled) availableArticles.push(item);
							else unavailableArticles.push(item);
						});
						setArticlesList(availableArticles.concat(unavailableArticles));
					}
				});
			}
		},
		[articles, articlesList.length, token]
	);

	useEffect(
		function RetrieveCustomerInformation() {
			if (customer === undefined && order?.customer_id && token && token.length) {
				getCustomer(token, order?.customer_id!).then((result) => {
					setCustomer(result as Customer);
				});
			}
		},
		[customer, order?.customer_id, token]
	);

	useEffect(
		function RetrieveDepartmentsFromCompany() {
			if (order?.customer_id && token && token.length)
				getDepartments(token, order?.customer_id!).then((response) => {
					if (response) {
						var deps: ListItem[] = [];
						response.forEach((comp: Department) => {
							var item: ListItem = {
								label: comp.name,
								value: comp.id,
							};
							deps.push(item);
						});
						setDepartments(deps);
					}
				});
		},
		[order?.customer_id, token]
	);

	useEffect(
		function CheckIfWarningNeedsToBeShown() {
			if (productionOrders.length >= 10) setOrderSizeWarning(true);
			else setOrderSizeWarning(false);
		},
		[productionOrders.length]
	);

	// Adds the edit button at the end of the select options
	const Option = (comps: any) => {
		return (
			<>
				<components.Option {...comps}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
					>
						<div>{comps.children}</div>
						<div>
							<Button
								variant="outline-primary"
								onClick={(e) => {
									e.stopPropagation();
									navigate(`/profile?user=${comps.value}&prev=order`);
								}}
							>
								<Icon.PencilSquare />
							</Button>
						</div>
					</div>
				</components.Option>
			</>
		);
	};

	function copyURL() {
		let sharableURL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_AUTH0_DEV_REDIRECT_URL : process.env.REACT_APP_AUTH0_REDIRECT_URL;

		navigator.clipboard.writeText(`${sharableURL}/status?orderid=${orderID}`);
		toastManager("info", `Copied to clipboard!`, `Copied a sharable URL to your clipboard`);
	}

	return (
		<>
			<Navigation />

			{isAuthenticated && (
				<Container className="paddingTopBottom">
					<BackButton
						route="/orders"
						title="orders"
					/>
					<h4 className="center">{customer?.name}</h4>

					<h1>Order</h1>
					{orderSizeWarning && (
						<Alert
							variant="warning"
							className="middle"
						>
							<Icon.ExclamationLg /> Warning! <br />
							An order can only be shipped in it's entirety and will only be shipped after each product is produced.
							<br /> An order above 10 products could have an increased delivery time.
							<Icon.ExclamationLg />
						</Alert>
					)}
					<hr />
					<Row>
						<Col
							className="fw-bold"
							sm={1}
						>
							{" "}
							Reference:
						</Col>{" "}
						<Col sm={3}>{order?.referenceId}</Col>
					</Row>
					<Row>
						{order?.exact_order_number === null && (
							<>
								<Col
									className="fw-bold"
									sm={1}
								>
									{" "}
									ExactID:
								</Col>{" "}
								<Col sm={3}>unassigned </Col>
							</>
						)}
						{order?.exact_order_number !== null && (
							<>
								<Col
									className="fw-bold"
									sm={1}
								>
									{" "}
									ExactID:
								</Col>{" "}
								<Col sm={3}>{order?.exact_order_number} </Col>
							</>
						)}
					</Row>
					<Row>
						<StatusComponent orderid={orderID} />

						<Col className="right">
							<Button
								size="sm"
								onClick={copyURL}
							>
								<Icon.ShareFill /> Copy the public status URL to share
							</Button>
						</Col>
					</Row>
					<br />
					<Row>
						{order?.locked === null && (
							<Col>
								<br className="d-block d-sm-none" />
								<Button
									className="right"
									variant="danger"
									onClick={handleRemoveOrder}
								>
									<Icon.Trash /> Delete order
								</Button>
							</Col>
						)}
					</Row>
					<hr />
					<>
						<Row>
							<Col>
								<p> Current production orders</p>
							</Col>
						</Row>
						<ListGroup variant="flush">
							<ListGroup.Item>
								<Row>
									<Col className="fw-bold">Name</Col>
									<Col className="fw-bold">PR number</Col>
									<Col className="fw-bold">Status</Col>
									<Col className="fw-bold d-none d-sm-block">Product</Col>
									{order?.locked === null && (
										<Col
											className="fw-bold d-none d-sm-block"
											sm={1}
										>
											Impression
										</Col>
									)}
									{order?.locked !== null && (
										<>
											<Col
												className="fw-bold d-none d-sm-block"
												sm={1}
											>
												Impression
											</Col>
										</>
									)}
								</Row>
							</ListGroup.Item>
						</ListGroup>
						<ListGroup variant="flush">{productionOrdersArray}</ListGroup>
						<hr />
					</>

					{order?.locked === null && (
						<Row>
							<p>Add users to this order </p>
							<Col>
								<Select
									components={{ Option }}
									isMulti
									isSearchable
									isClearable
									isDisabled={order?.locked !== null}
									options={consumersList}
									closeMenuOnSelect={false}
									required
									onChange={(value) => {
										setSelectedConsumers(value as ListItem[]);
									}}
									value={selectedConsumers}
								/>
							</Col>
							<Col sm={3}>
								<br className="d-block d-sm-none" />
								<Button
									onClick={handleAddUserOpen}
									disabled={order?.locked !== null}
									variant="outline-primary"
								>
									<Icon.PersonFillAdd /> Add a new user to {customer?.name}
								</Button>
							</Col>
						</Row>
					)}

					<Form validated>
						{order?.confirmed === null && (
							<>
								<br />
								<br />
								<br />
								<SelectProducts
									products={articlesList}
									selectedConsumers={selectedConsumers}
									disabled={order?.locked !== null}
								/>
							</>
						)}
						<>
							<br />
							<Row>
								{selectedConsumers.length !== 0 && (
									<Col>
										{!showOrderCreated && order?.locked === null && (
											<Button
												type="submit"
												onClick={handleNewOrder}
											>
												<Icon.PlusLg />
												Add to current order
											</Button>
										)}
										{showOrderCreated && (
											<Button variant="primary">
												<Icon.CheckLg /> Current order saved
											</Button>
										)}
									</Col>
								)}
								{productionOrders.length !== 0 && (
									<Col>
										{order?.locked === null && (
											<Button
												variant="primary"
												className="right"
												onClick={handleAreYouSureOpen}
											>
												<Icon.BagCheck />
												Lock order
											</Button>
										)}
										{order?.locked !== null && (
											<Button
												variant="primary"
												disabled
												className="right"
											>
												<Icon.BagCheck color="gold" />
												Order locked
											</Button>
										)}
									</Col>
								)}
							</Row>
						</>
					</Form>
					<br />
					<br />
					<br />
					<br />
					<Modal
						show={showAddUser}
						onHide={handleAddUserClose}
						keyboard
						centered
					>
						{order?.customer_id !== undefined && (
							<Form validated>
								<Modal.Header>Add a new user for {customer?.name}</Modal.Header>
								<Modal.Body>
									<Row>
										<Col>
											<Form.Text>First name:</Form.Text>
											<Form.Control
												required
												type="text"
												autoComplete="off"
												id="given_name"
											></Form.Control>
										</Col>
										<Col>
											<Form.Text>Last name:</Form.Text>
											<Form.Control
												required
												type="text"
												autoComplete="off"
												id="family_name"
											></Form.Control>
										</Col>
										<Col>
											<Form.Text>Nickname:</Form.Text>
											<Form.Control
												required
												type="text"
												autoComplete="off"
												id="short_name"
											></Form.Control>
										</Col>
									</Row>
									<Row>
										<Form.Text>Department</Form.Text>
										<Select
											required
											isSearchable
											inputId="department"
											options={departments}
											onChange={(value) => setSelectedDepartment(value?.value)}
										/>
									</Row>
								</Modal.Body>
								<Modal.Footer>
									<Button onClick={handleAddUser}>
										<Icon.PersonFillAdd />
										Add to {customer?.name}
									</Button>
								</Modal.Footer>
							</Form>
						)}
					</Modal>
					<Modal
						show={showAreYouSure}
						onHide={handleAreYouSureClose}
						keyboard
						centered
					>
						{productionOrders.length !== 0 && (
							<>
								<Modal.Header>
									<p> Are you sure?</p>
								</Modal.Header>
								<Modal.Body>
									By pressing 'Lock order' this order cannot be changed and will get this order produced. There could be payment requirements after pressing confirm. <br />
									<br />
									By pressing 'Lock order' you agree to EarsOnly's{" "}
									<a
										className="termsandconditions"
										href="https://earsonly.nl/terms"
									>
										Terms & Conditions
									</a>{" "}
									<br />
								</Modal.Body>
								<Modal.Footer>
									<Button
										variant="secondary"
										onClick={handleAreYouSureClose}
									>
										<Icon.ArrowReturnLeft />
										Go back
									</Button>
									<Button
										variant="primary"
										className="right"
										type="submit"
										onClick={lockOrders}
									>
										<Icon.BagCheckFill color="white" />
										Lock order
									</Button>
								</Modal.Footer>
							</>
						)}{" "}
						{productionOrders.length === 0 && (
							<Modal.Footer>
								<Modal.Header>
									<p> Cannot lock empty order </p>
								</Modal.Header>
								<Modal.Body>
									Sorry, you can't lock an empty order, <br />
									please add a consumer with a product to this order to lock it.
								</Modal.Body>
								<Button
									variant="danger"
									className="right"
									type="button"
									onClick={handleAreYouSureClose}
								>
									<Icon.XCircleFill color="white" />
									No productionorders, close this popup
								</Button>
							</Modal.Footer>
						)}
					</Modal>
				</Container>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
